import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _22c2ea9d = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _26d84f75 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _3e6169c2 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _1f860aa5 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _225ae91a = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _466632b6 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _1b06c745 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _5d7edfb9 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _f9b39bfc = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _37bad0d8 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _257f1772 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _00240abd = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _a7d03c0c = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _d875cbb0 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _a64cd704 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _18f58159 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _14131ca3 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _c364d8ce = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _21392f3a = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _ccbb6598 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _22c2ea9d,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _26d84f75,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _3e6169c2,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _1f860aa5,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _225ae91a,
    name: "receipt"
  }, {
    path: "/search",
    component: _466632b6,
    name: "search"
  }, {
    path: "/search-booking",
    component: _1b06c745,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _5d7edfb9,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _f9b39bfc,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _37bad0d8,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _257f1772,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _00240abd,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _a7d03c0c,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _d875cbb0,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _a64cd704,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _18f58159,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _14131ca3,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _c364d8ce,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _21392f3a,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _ccbb6598,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
